#Nav a{
    color: blue;
    text-decoration:none;
  }
  
  #Nav a:hover {
   color: pink;
   text-decoration: underline;
  }


  .NavLink {
    max-height: 1;
  }

  .nav-wrapper {
    background-color: #ffffff !important;
    font-size: 12px;
    font-weight: bold;
  }

  .img {
      max-height: 55px;
  }