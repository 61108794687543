/* https://dev.to/skptricks/create-simple-popup-example-in-react-application-5g7f */
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.goat {
    border: 3px solid #4386729d;
}
.popup2 {
  position: absolute;
  top: -10px;
  bottom: -100px;
  margin-bottom: 100px;
  width: 100%;
}

.roundContainer {
  border-radius: 20px;
}

.popupl {
  position: absolute;
  width: 60%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup\_inner {
  position: fixed;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  border-radius: 20px;
  background: white;
}
