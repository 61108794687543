h6 {
  color: teal;
  text-transform: capitalize;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.logo {
  position: relative;
  left: 20%;
  top: 50%;
  -webkit-transform: translate(0%, 5%);
  transform: translate(0%, 5%);
}
