.label {
    margin: 300;
    padding: 20;
    font-weight: bold
}

.span-pad {
    margin-bottom: 220px;
    padding: 50px;
    font-weight: bold
}

.h6 {
    margin-bottom: 40px;
    margin-top: 30px;
    font-weight: bold;
}

.h5 {
    margin-bottom: 40px;
    margin-top: 30px;
    font-weight: bold;
}